import {
  Event,
  Event_ControlSource,
  EventType,
  Severity,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import { TFunction } from 'i18next';

export class EventModel {
  readonly resourceName: string;

  readonly lastOcurrenceTime: string | undefined;

  readonly severity: Severity;

  readonly type: EventType;

  readonly key: number;

  readonly payload: { key: string; value: string }[];

  readonly manualControl: Event_ControlSource;

  constructor(event: Event, uniqueKey: number) {
    if (event.lastOccuranceTime) {
      const seconds = Number(event.lastOccuranceTime.seconds);
      const date = new Date(seconds * 1000);
      const readableDate = date.toLocaleString();
      this.lastOcurrenceTime = readableDate;
    } else {
      this.lastOcurrenceTime = undefined;
    }
    this.payload = event.payloadInfo.map(({ key, value }) => ({
      key,
      value: value.replace(/,/g, ', '),
    }));
    this.resourceName = event.resourceName;
    this.severity = event.severity;
    this.type = event.type;
    this.key = uniqueKey;
    this.manualControl = event.controlSource;
  }

  getControlType(t: TFunction) {
    switch (this.manualControl) {
      case Event_ControlSource.MANUAL:
        return `${t('system.event.control.manual')}: `;
      case Event_ControlSource.AUTOMATED:
        return `${t('system.event.control.automatic')}: `;
      default:
        return '';
    }
  }
}
