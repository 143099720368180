import { Grid } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { Suspense } from 'react';
import { Outlet } from 'react-router';
import { RenderDrawerContentProps } from '~/pages/layout/components/Navigation/Drawer';
import { DrawerContainer } from '~/pages/layout/components/Navigation/DrawerContainer';
import { loadAuthUser } from '~/shared/auth/auth-utils';
import { Snackbar } from '~/shared/components/Snackbar/Snackbar';
import { DRAWER_WIDTH } from './layout-constants';

export const rootLoader = (queryClient: QueryClient) => async () => {
  await loadAuthUser(queryClient);
  return null;
};

export function AppLayout({ renderDrawerContent }: RenderDrawerContentProps) {
  return (
    <Grid container>
      <DrawerContainer renderDrawerContent={renderDrawerContent} />

      <Grid
        container
        flexDirection="column"
        flexWrap="nowrap"
        minHeight="100vh"
        width={{
          xs: '100%',
          md: `calc(100% - ${DRAWER_WIDTH}px)`,
        }}
        pb={(theme) => theme.spacing(5)}
        pt={(theme) => ({ xs: theme.spacing(14), md: theme.spacing(5) })}
        px={(theme) => ({
          xs: theme.spacing(2),
          md: theme.spacing(4),
          lg: theme.spacing(8),
        })}
      >
        {/*
          No fallback because we don't want to show a flash or spinner.
          The navigation will remain visible.
        */}
        <Suspense>
          <Outlet />
        </Suspense>

        <Snackbar />
      </Grid>
    </Grid>
  );
}
