import { ActionFunction, redirect } from 'react-router';
import { t } from 'i18next';
import { AuthService } from '~/services/auth/AuthService';
import { createRoutes } from '~/shared/models/create-routes';
import { validatePassword } from './validatePassword';

export type CreateNewPasswordFormData = {
  password: string;
  passwordConfirm: string;
  oobCode: string;
};

export const resetPasswordAction =
  (auth: AuthService): ActionFunction =>
  async ({ request }) => {
    const formData = await request.formData();
    const { password, passwordConfirm, oobCode } = Object.fromEntries(
      formData.entries(),
    ) as CreateNewPasswordFormData;

    validatePassword(password, passwordConfirm, oobCode);

    try {
      await auth.confirmPasswordReset(oobCode, password);

      return redirect(createRoutes.SignIn);
    } catch (error) {
      throw new Error(t('auth.errors.resetPasswordError'));
    }
  };
