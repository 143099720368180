import { QueryClient } from '@tanstack/react-query';
import { ListSystemsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { LoaderFunctionArgs } from 'react-router';
import { listSystemsQuery } from '~/shared/api/system.queries';
import {
  checkAuthorization,
  requireUserPermissionForSystemId,
} from '~/shared/auth/auth-utils';
import { createSystemBomRoute } from '~/shared/models/create-routes';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { noSystemIdRedirect } from '~/shared/utils/no-system-id-redirect';
import { assertIsDefined } from '~/types/assert-type';

export type BillOfMaterialsPageLoaderResult = {
  systems: ListSystemsResponse;
};

export const billOfMaterialsPageLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<
    BillOfMaterialsPageLoaderResult | Response
  > => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;

    const listSystemsResponsePromise =
      queryClient.ensureQueryData(listSystemsQuery);

    if (!systemId) {
      return await noSystemIdRedirect(
        listSystemsResponsePromise,
        createSystemBomRoute,
      );
    }

    const listSystemsResponse = await listSystemsResponsePromise;

    const listSystemsModel = new ListSystemsModel(listSystemsResponse);

    const system = listSystemsModel.systemById(systemId);

    assertIsDefined(system);

    await requireUserPermissionForSystemId(
      queryClient,
      request,
      SystemPermission_Permission.ADMIN,
      systemId,
    );

    return {
      systems: listSystemsResponse,
    };
  };
