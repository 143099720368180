import { Timestamp } from '@bufbuild/protobuf';
import {
  ListIssuesResponse,
  OrderBy,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { Severity } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import {
  IssueStatus,
  IssueType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/issue_pb';
import dayjs, { Dayjs } from 'dayjs';
import { aqoraClient } from '~/services/api/aqora-client';

export const listIssues = async ({
  orderBy,
  pageSize,
  pageToken,
  status,
  resourceName,
  rangeFrom = dayjs().subtract(1, 'year'),
  rangeTo = dayjs(),
  severity,
  issueType,
}: {
  orderBy: OrderBy;
  pageSize: number;
  pageToken?: string;
  status?: IssueStatus;
  resourceName?: string;
  severity?: Severity;
  rangeFrom?: Dayjs;
  rangeTo?: Dayjs;
  issueType?: IssueType;
}): Promise<ListIssuesResponse> =>
  await aqoraClient.listIssues({
    orderBy: [orderBy],
    pageSize: BigInt(pageSize),
    pageToken,
    rangeFrom: Timestamp.fromDate(rangeFrom.toDate()),
    rangeTo: Timestamp.fromDate(rangeTo.toDate()),
    status,
    resourceName,
    severities: severity ? [severity] : undefined,
    issueTypes: issueType ? [issueType] : undefined,
  });

export const listIssuesForSystem = async ({
  systemName,
  status,
}: {
  systemName: string;
  status?: IssueStatus;
}): Promise<ListIssuesResponse> => {
  const now = new Date();
  const lastYear = new Date(now.setFullYear(now.getFullYear() - 1));
  return await aqoraClient.listIssues({
    rangeFrom: Timestamp.fromDate(lastYear),
    rangeTo: Timestamp.fromDate(new Date()),
    resourceName: systemName,
    status: status ?? IssueStatus.OPEN,
  });
};
