import {
  captureConsoleIntegration,
  getCurrentScope,
  init,
  makeFetchTransport,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import {
  BUILD_ID,
  ENVIRONMENT,
  RELEASE_VERSION,
  SENTRY_DSN,
  TRANSPORT_URL,
} from '~/services/environment/environment';
import { ConsentTransport } from '~/services/error-capturing/ConsentTransport';

const escapedUrl = TRANSPORT_URL.replace(/\./g, '\\.').replace(/\//g, '\\/');
const urlRegex = new RegExp(`^${escapedUrl}`);

let transport: ConsentTransport | undefined;

export function initErrorCapturing({
  sentryDsn = SENTRY_DSN,
  buildId = BUILD_ID,
} = {}) {
  if (!sentryDsn) {
    return;
  }

  if (ENVIRONMENT === 'local') {
    return;
  }

  init({
    environment: ENVIRONMENT,
    dsn: sentryDsn,
    transport: (options) => {
      const newTransport = new ConsentTransport(makeFetchTransport(options));
      transport = newTransport;
      return transport;
    },
    release: RELEASE_VERSION,
    tracePropagationTargets: [urlRegex],
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 1.0,
  });

  if (buildId) {
    getCurrentScope().setTag('build_id', buildId);
  }
}

export function setErrorCapturingConsent(hasConsent: boolean) {
  transport?.setConsent(hasConsent);
}
