import { EventType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import { ReservoirPredictionsAvailabilityModel } from '~/pages/System/models/ReservoirPredictionsAvailabilityModel';
import { listEvents } from '~/services/api/fetchers/events.api';
import { makeQuery } from '~/shared/api/internal/utils';

export const availableReservoirPredictionsQuery = ({
  resourceName,
}: {
  resourceName: string;
}) =>
  makeQuery({
    queryKey: [
      'availableReservoirPredictions',
      {
        resourceName,
      },
    ],
    queryFn: async () => {
      const eventsResponse = await listEvents({
        resourceName,
        filterTypes: [EventType.CONVEYOR_PREDICTION],
      });

      return new ReservoirPredictionsAvailabilityModel(eventsResponse);
    },
  });
