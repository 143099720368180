import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router';
import { availableReservoirPredictionsQuery } from '~/pages/System/api/availableReservoirPredictions.queries';
import { ReservoirPredictionsAvailabilityModel } from '~/pages/System/models/ReservoirPredictionsAvailabilityModel';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { checkAuthorization } from '~/shared/auth/auth-utils';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { assertIsDefined } from '~/types/assert-type';

export type ReservoirParametersSidebarSectionLoaderResult = {
  reservoirPredictionsAvailability: ReservoirPredictionsAvailabilityModel;
};

export const reservoirParametersSidebarSectionLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    await checkAuthorization(queryClient, request);

    const { systemId, reservoirId } = params;

    assertIsDefined(reservoirId);

    assertIsDefined(systemId);

    const listSystemsResponsePromise =
      queryClient.ensureQueryData(listSystemsQuery);

    const [listSystemsResponse] = await Promise.all([
      listSystemsResponsePromise,
    ]);

    const listSystemsModel = new ListSystemsModel(listSystemsResponse);

    const system = listSystemsModel.systemById(systemId);

    assertIsDefined(system);

    const resevoir = system.reservoirById(reservoirId);

    assertIsDefined(resevoir);

    if (
      !ReservoirPredictionsAvailabilityModel.shouldCheckReservoirPredictions(
        system,
      )
    ) {
      return {
        reservoirPredictionsAvailability:
          ReservoirPredictionsAvailabilityModel.fromEmptyEventResponse(),
      };
    }

    const reservoirPredictionsAvailability = await queryClient.ensureQueryData(
      availableReservoirPredictionsQuery({
        resourceName: resevoir.name,
      }),
    );

    return {
      reservoirPredictionsAvailability,
    };
  };
