import { keepPreviousData } from '@tanstack/react-query';
import {
  OrderBy,
  OrderBy_AvailableFields,
  OrderBy_SortOrder,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { Severity } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import {
  IssueStatus,
  IssueType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/issue_pb';
import { Dayjs } from 'dayjs';
import {
  listIssues,
  listIssuesForSystem,
} from '~/services/api/fetchers/issues.api';
import { makeQuery } from '~/shared/api/internal/utils';
import { IssuesModel } from '~/shared/models/issues/IssuesModel';
import { assertIsNonBlankString } from '~/types/assert-type';

export function createInfinityListIssuesQuery({
  pageSize,
  orderBy,
  status,
  resourceName,
  rangeFrom,
  rangeTo,
  severity,
  issueType,
}: {
  pageSize: number;
  orderBy: OrderBy;
  status?: IssueStatus;
  rangeFrom?: Dayjs;
  rangeTo?: Dayjs;
  resourceName?: string;
  severity?: Severity;
  issueType?: IssueType;
}) {
  return {
    queryKey: [
      'listIssuesInfinity',
      pageSize,
      OrderBy_AvailableFields[orderBy.field],
      OrderBy_SortOrder[orderBy.sortOrder],
      status ? IssueStatus[status] : undefined,
      resourceName,
      severity ? Severity[severity] : undefined,
      issueType ? IssueType[issueType] : undefined,
      rangeFrom?.toISOString(),
      rangeTo?.toISOString(),
    ],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const listIssuesResponse = await listIssues({
        orderBy,
        pageSize,
        rangeFrom,
        rangeTo,
        pageToken: pageParam,
        status,
        resourceName,
        severity,
        issueType,
      });
      return new IssuesModel(listIssuesResponse);
    },
    getNextPageParam: (lastPage: IssuesModel) => {
      const hasNextPageToken = lastPage?.nextPageToken;
      const hasFetchedEqualPageSize = lastPage?.total === pageSize;
      return hasNextPageToken && hasFetchedEqualPageSize
        ? lastPage.nextPageToken
        : undefined;
    },
    initialPageParam: undefined,
    placeholderData: keepPreviousData,
  };
}

export const listOpenIssuesForSystemQuery = ({
  systemName,
}: {
  systemName: string;
}) => {
  assertIsNonBlankString(systemName);
  return makeQuery({
    queryKey: ['listOpenIssuesForSystem', systemName],
    queryFn: async () =>
      await listIssuesForSystem({
        systemName,
        status: IssueStatus.OPEN,
      }),
  });
};
