import { ListSystemsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { redirect } from 'react-router';
import { createRoutes } from '~/shared/models/create-routes';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';

export const noSystemIdRedirect = async (
  listSystemsResponsePromise: Promise<ListSystemsResponse>,
  routeCreator: (systemId: string) => string,
): Promise<Response> => {
  const listSystemsResponse = await listSystemsResponsePromise;

  const { systems } = new ListSystemsModel(listSystemsResponse);

  if (systems.length === 0) {
    return redirect(createRoutes.Systems);
  }

  const redirectUrl = routeCreator(systems[0].id);
  return redirect(redirectUrl);
};
