import { Grid, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DotLabel } from '../DotLabel/DotLabel';

function useGenerateNewSystemPath() {
  const location = useLocation();
  const params = useParams();
  return (systemId: string) =>
    location.pathname.replace(`${params.systemId}`, `${systemId}`);
}

export type SystemInfo = { title: string; id: string };

export type SystemSelectProps = {
  selectedSystem: string;
  systems: SystemInfo[];
};

export function SystemSelect({ selectedSystem, systems }: SystemSelectProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const generatePath = useGenerateNewSystemPath();

  const labelId = useId();

  return (
    <Grid container gap={1}>
      <DotLabel label={t('system.systemSelector.dropdownLabel')} id={labelId} />
      <Select labelId={labelId} value={selectedSystem}>
        {systems.map((system) => (
          <MenuItem
            key={system.id}
            value={system.title}
            onClick={() => navigate(generatePath(system.id), { replace: true })}
          >
            {system.title}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
}
