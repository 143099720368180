import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router';
import { RainwaterKpisModel } from '~/pages/Dashboard/models/RainwaterKpisModel';
import { WaterStoredKpiModel } from '~/pages/Dashboard/models/WaterStoredKpiModel';
import { listOpenIssuesForSystemQuery } from '~/shared/api/issues.queries';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { IssuesLoaderData } from '~/shared/hooks/issues-hooks';
import { SystemLoaderData } from '~/shared/hooks/systems-hooks';
import { createDashboardRoute } from '~/shared/models/create-routes';
import { nameFromId } from '~/shared/models/id-utils';
import {
  getWaterStoredKpiQuery,
  listRainwaterKpisQuery,
} from './api/kpi.queries';
import { checkAuthorization } from '~/shared/auth/auth-utils';
import { noSystemIdRedirect } from '~/shared/utils/no-system-id-redirect';

export type DashboardPageLoaderResult = SystemLoaderData &
  IssuesLoaderData & {
    rainwaterKpis: RainwaterKpisModel;
    waterStoredKpi: WaterStoredKpiModel;
  };

export const dashboardLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<Response | DashboardPageLoaderResult> => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;

    const listSystemsResponsePromise =
      queryClient.ensureQueryData(listSystemsQuery);

    if (!systemId) {
      return await noSystemIdRedirect(
        listSystemsResponsePromise,
        createDashboardRoute,
      );
    }

    const [
      listSystemsResponse,
      listIssuesResponse,
      rainwaterKpisModel,
      waterStoredKpiModel,
    ] = await Promise.all([
      listSystemsResponsePromise,
      queryClient.ensureQueryData(
        listOpenIssuesForSystemQuery({ systemName: nameFromId(systemId) }),
      ),
      queryClient.ensureQueryData(listRainwaterKpisQuery(nameFromId(systemId))),

      queryClient.ensureQueryData(getWaterStoredKpiQuery(nameFromId(systemId))),
    ]);

    return {
      systems: listSystemsResponse,
      issues: listIssuesResponse,
      rainwaterKpis: rainwaterKpisModel,
      waterStoredKpi: waterStoredKpiModel,
    };
  };
