import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export function useLastSystemId() {
  const { systemId } = useParams<{ systemId: string }>();
  const [lastSystemId, setLastSystemId] = useState<string | undefined>();

  useEffect(() => {
    if (systemId) {
      setLastSystemId(systemId);
    }
  }, [systemId]);

  return lastSystemId;
}
