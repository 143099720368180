import { QueryClient } from '@tanstack/react-query';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { LoaderFunctionArgs } from 'react-router';
import { listSystemUsersQuery } from '~/shared/api/systemUsers.queries';
import { SystemUsersModel } from '~/shared/models/users/SystemUsersModel';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { requireUserPermissionForSystemId } from '~/shared/auth/auth-utils';
import { createSystemUsersRoute } from '~/shared/models/create-routes';
import { nameFromId } from '~/shared/models/id-utils';
import { noSystemIdRedirect } from '~/shared/utils/no-system-id-redirect';

export type SystemUsersLoaderData = {
  systemUsers: SystemUsersModel;
};

export const systemUsersLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<SystemUsersLoaderData | Response> => {
    const { systemId } = params;

    const listSystemsResponsePromise =
      queryClient.ensureQueryData(listSystemsQuery);

    if (!systemId) {
      return await noSystemIdRedirect(
        listSystemsResponsePromise,
        createSystemUsersRoute,
      );
    }

    await requireUserPermissionForSystemId(
      queryClient,
      request,
      SystemPermission_Permission.USER_MANAGEMENT,
      systemId,
    );

    const systemUsers = await queryClient.ensureQueryData(
      listSystemUsersQuery(nameFromId(systemId)),
    );

    return { systemUsers };
  };
