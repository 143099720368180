import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';
import { initErrorCapturing } from '~/pages/layout/error-capturing';
import { createRouter } from '~/pages/router';
import { loadCookiebarSdk } from '~/services/cookiebar/onetrust';
import { ENABLE_MOCKS } from '~/services/environment/environment';
import { configLocales } from '~/services/i18n/i18n';
import './globals';

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(durationPlugin);

(async () => {
  initErrorCapturing();
  loadCookiebarSdk();
  configLocales();

  if (ENABLE_MOCKS) {
    const { worker } = await import('~/shared/tests/mocks/browser');
    worker.start({
      onUnhandledRequest(req, print) {
        if (req.url.pathname.startsWith('/wavin.aqora.v2.AqoraService/')) {
          print.warning();
        }
      },
    });
  }

  const router = await createRouter();

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
    </React.StrictMode>,
  );
})();
