import { QueryClient } from '@tanstack/react-query';
import { ListMetricsRequest_AggregationType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { LoaderFunctionArgs, redirect } from 'react-router';
import { listMetricsQuery } from '~/shared/api/metrics.queries';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { checkAuthorization } from '~/shared/auth/auth-utils';
import { SensorReadingsData } from '~/shared/hooks/useLatestMetrics';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { assertIsDefined } from '~/types/assert-type';

export const sensorReadingsLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<SensorReadingsData | Response> => {
    await checkAuthorization(queryClient, request);
    const { productId, systemId } = params;

    assertIsDefined(productId);
    assertIsDefined(systemId);

    const listSystemsResponse =
      await queryClient.ensureQueryData(listSystemsQuery);

    const listSystemsModel = new ListSystemsModel(listSystemsResponse);

    const product = listSystemsModel
      .systemById(systemId)
      ?.productById(productId);

    if (!product) {
      return redirect('..');
    }

    const metrics = await queryClient.ensureQueryData(
      listMetricsQuery({
        targets: product.availableMetricTargets,
        aggregation: ListMetricsRequest_AggregationType.MOST_RECENT_ONLY,
      }),
    );
    return { metrics };
  };
