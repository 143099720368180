import { t } from 'i18next';
import { ActionFunction } from 'react-router';
import { AuthService } from '~/services/auth/AuthService';
import { isWavinOrOrbiaEmail } from '~/shared/utils/is-wavin-email';

export type PasswordResetAction = {
  didSendPasswordResetRequest: boolean;
};

export type PasswordResetByEmailFormData = {
  email: string;
};

export const forgetPasswordAction =
  (auth: AuthService): ActionFunction =>
  async ({ request }): Promise<PasswordResetAction | Error> => {
    const formData = await request.formData();
    const { email } = Object.fromEntries(
      formData,
    ) as PasswordResetByEmailFormData;

    if (typeof email !== 'string') {
      throw new Error('Expected email to be a text');
    }

    if (isWavinOrOrbiaEmail(email)) {
      throw new Error(t('auth.errors.cantResetADPassword'));
    }

    await auth.sendPasswordResetEmail(email.toLowerCase());
    return { didSendPasswordResetRequest: true };
  };
