import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'dayjs/locale/en-gb';
import { PropsWithChildren, useEffect } from 'react';
import { Outlet } from 'react-router';
import { setErrorCapturingConsent } from '~/pages/layout/error-capturing';
import { useCookiebar } from '~/pages/layout/hooks/useCookiebar';
import { useGoogleAnalytics } from '~/pages/layout/hooks/useGoogleAnalytics';
import { queryClient } from '~/shared/api/query-client';
import { AuthProvider } from '~/shared/hooks/useIsSignedIn';
import { theme } from '~/shared/theme/theme';

export function Providers({ children }: PropsWithChildren) {
  const consentedCookieGroups = useCookiebar();

  useGoogleAnalytics({
    enabled: consentedCookieGroups.analytics,
  });

  useEffect(() => {
    setErrorCapturingConsent(consentedCookieGroups.performance);
  }, [consentedCookieGroups.performance]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AuthProvider>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            {children}
          </LocalizationProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export function RouterProviders() {
  return (
    <Providers>
      <Outlet />
      <ReactQueryDevtools initialIsOpen={false} />
    </Providers>
  );
}
