import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router';
import { listNotesQuery } from '~/pages/SystemNotes/api/systemNotes.queries';
import { NotesListModel } from '~/pages/SystemNotes/models/NotesListModel';
import { listSystemUsersQuery } from '~/shared/api/systemUsers.queries';
import { SystemUsersModel } from '~/shared/models/users/SystemUsersModel';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { requireUserRoleForSystemId } from '~/shared/auth/auth-utils';
import { createSystemNotesRoute } from '~/shared/models/create-routes';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { assertIsDefined } from '~/types/assert-type';
import { noSystemIdRedirect } from '~/shared/utils/no-system-id-redirect';

export type SystemNotesLoaderData = {
  systemNotes: NotesListModel;
  systemUsers: SystemUsersModel;
};

export const systemNotesLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<SystemNotesLoaderData | Response> => {
    const { systemId } = params;

    const listSystemsReponsePromise =
      queryClient.ensureQueryData(listSystemsQuery);

    if (!systemId) {
      return await noSystemIdRedirect(
        listSystemsReponsePromise,
        createSystemNotesRoute,
      );
    }

    await requireUserRoleForSystemId(
      queryClient,
      request,
      ['internal_admin', 'internal_engineer', 'admin', 'installer'],
      systemId,
    );

    const listSystemsReponse = await listSystemsReponsePromise;

    const listSystemsModel = new ListSystemsModel(listSystemsReponse);

    const system = listSystemsModel.systemById(systemId);

    assertIsDefined(system);

    const systemNotesPromise = queryClient.ensureQueryData(
      listNotesQuery(system),
    );

    const systemUsersPromise = queryClient.ensureQueryData(
      listSystemUsersQuery(system.name),
    );

    const [systemNotes, systemUsers] = await Promise.all([
      systemNotesPromise,
      systemUsersPromise,
    ]);

    return { systemNotes, systemUsers };
  };
