import { ListEventsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import {
  Event,
  EventType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import dayjs from 'dayjs';
import { SystemModel } from '~/shared/models/system/SystemModel';

export class ReservoirPredictionsAvailabilityModel {
  readonly predictionsAvailable: boolean = false;

  constructor(eventsResponse: ListEventsResponse) {
    const conveyorPredictionEvents = eventsResponse.events.filter(
      (event: Event) => event.type === EventType.CONVEYOR_PREDICTION,
    );

    if (conveyorPredictionEvents.length === 0) {
      this.predictionsAvailable = false;
      return;
    }

    const latestEvent = conveyorPredictionEvents.reduce((latest, event) =>
      (event.lastOccuranceTime?.seconds ?? 0) >
      (latest.lastOccuranceTime?.seconds ?? 0)
        ? event
        : latest,
    );

    const currentTime = dayjs();
    const lastOccuranceTime = dayjs(latestEvent.lastOccuranceTime?.toDate());

    if (lastOccuranceTime.isValid()) {
      this.predictionsAvailable =
        currentTime.diff(lastOccuranceTime, 'hour') < 24;
    }
  }

  static fromEmptyEventResponse() {
    return new ReservoirPredictionsAvailabilityModel(new ListEventsResponse());
  }

  static shouldCheckReservoirPredictions(currentSystem: SystemModel) {
    return currentSystem.automation === 'on';
  }
}
