import { Box, Skeleton, Toolbar } from '@mui/material';
import { Link, useNavigation } from 'react-router';
import LogoImg from '~/pages/layout/components/Navigation/logo.png';
import { useLastSystemId } from '~/pages/layout/hooks/useLastSystemId';
import { createDashboardRoute } from '~/shared/models/create-routes';

const loadTime = Date.now();

const LOAD_TIME_TIMEOUT = 5000;

export function Logo({
  loadTimeTimeout = LOAD_TIME_TIMEOUT,
}: {
  loadTimeTimeout?: number;
}) {
  const systemId = useLastSystemId();
  const navigation = useNavigation();

  const initialPageLoad = Date.now() - loadTime < loadTimeTimeout;

  const showLoading =
    navigation.state === 'loading' || navigation.state === 'submitting';

  return (
    <Toolbar
      variant="dense"
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {showLoading && !initialPageLoad ? (
        <Skeleton width={90} data-testid="logo-skeleton" />
      ) : (
        <Link to={createDashboardRoute(systemId)}>
          <Box
            component="img"
            src={LogoImg}
            alt="Wavin logo"
            sx={{ width: '100%', height: 'auto' }}
          />
        </Link>
      )}
    </Toolbar>
  );
}
