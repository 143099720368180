import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { checkAuthorization } from '~/shared/auth/auth-utils';
import { createDownloadsRoute } from '~/shared/models/create-routes';
import { noSystemIdRedirect } from '~/shared/utils/no-system-id-redirect';

export const downloadsLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;

    const listSystemsReponsePromise =
      queryClient.ensureQueryData(listSystemsQuery);

    if (!systemId) {
      return await noSystemIdRedirect(
        listSystemsReponsePromise,
        createDownloadsRoute,
      );
    }

    return { systems: await listSystemsReponsePromise };
  };
