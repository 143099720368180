import { QueryClient } from '@tanstack/react-query';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { redirect } from 'react-router';
import { authService } from '~/services/auth/auth-service';
import { SKIP_SIGNIN } from '~/services/environment/environment';
import { authUserQuery } from '~/shared/api/auth-user.queries';
import { TERMS_AND_CONDITIONS_VERSION } from '~/shared/auth/internal/user-constants';
import {
  createDashboardRoute,
  createRoutes,
  RouteValues,
} from '~/shared/models/create-routes';
import { UserRole } from '~/shared/models/system/RolesModel';
import { AuthUserModel } from '~/shared/models/auth-user/AuthUserModel';

export { TERMS_AND_CONDITIONS_VERSION };

function urlStringNoOrigin(url: URL) {
  return url.toString().replace(url.origin, '');
}

function createRedirectUrl(requestUrlString: string) {
  const requestUrl = new URL(requestUrlString);
  requestUrl.hash = window.location.hash;
  return urlStringNoOrigin(requestUrl);
}

function createUrl(redirectUrl: string, route: RouteValues) {
  const loginUrl = new URL(route, 'http://localhost');
  loginUrl.searchParams.set('redirect_url', redirectUrl);
  return urlStringNoOrigin(loginUrl);
}

export function hasAcceptedTerms(userTermsVersion: number | undefined) {
  return userTermsVersion === TERMS_AND_CONDITIONS_VERSION;
}

export async function getAuthUser(queryClient: QueryClient, request: Request) {
  await authService.initialized;

  if (!authService.isSignedIn && !SKIP_SIGNIN) {
    const redirectUrl = createRedirectUrl(request.url);
    const loginUrl = createUrl(redirectUrl, createRoutes.SignIn);

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(loginUrl);
  }

  return await queryClient.ensureQueryData(authUserQuery);
}

export async function ensureNotLoggedIn() {
  await authService.initialized;

  if (authService.isSignedIn) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(createDashboardRoute());
  }

  return null;
}

export async function checkAuthorizationWithPredicate(
  queryClient: QueryClient,
  request: Request,
  authPredicate: (authUser: AuthUserModel) => boolean,
) {
  const authUser = await getAuthUser(queryClient, request);

  if (!authUser || !authPredicate(authUser)) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(createRoutes.Unauthorized);
  }

  if (!hasAcceptedTerms(authUser.profile.lastAcceptedTermsAndConditions)) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(createRoutes.TermsConditions);
  }

  return null;
}

export function checkAuthorization(queryClient: QueryClient, request: Request) {
  return checkAuthorizationWithPredicate(queryClient, request, () => true);
}

export async function checkIfUserHasPermissionForSystem(
  queryClient: QueryClient,
  request: Request,
  systemId: string,
  requestedUserPermissions:
    | SystemPermission_Permission
    | SystemPermission_Permission[],
) {
  const authUser = await getAuthUser(queryClient, request);

  const permissions = Array.isArray(requestedUserPermissions)
    ? requestedUserPermissions
    : [requestedUserPermissions];

  return permissions.some(
    (permission) => authUser?.hasPermissionForSystemId(permission, systemId),
  );
}

export function requireUserPermission(
  queryClient: QueryClient,
  request: Request,
  permission: SystemPermission_Permission,
) {
  return checkAuthorizationWithPredicate(queryClient, request, (authUser) =>
    authUser.hasPermission(permission),
  );
}

export function requireUserPermissionForSystemId(
  queryClient: QueryClient,
  request: Request,
  requestedUserPermissions:
    | SystemPermission_Permission
    | SystemPermission_Permission[],
  systemId: string,
) {
  return checkAuthorizationWithPredicate(queryClient, request, (authUser) => {
    const permissions = Array.isArray(requestedUserPermissions)
      ? requestedUserPermissions
      : [requestedUserPermissions];

    return permissions.some((permission) =>
      authUser.hasPermissionForSystemId(permission, systemId),
    );
  });
}

export function requireUserRole(
  queryClient: QueryClient,
  request: Request,
  requestedUserRoles: UserRole | UserRole[],
) {
  return checkAuthorizationWithPredicate(queryClient, request, (authUser) => {
    const roles = Array.isArray(requestedUserRoles)
      ? requestedUserRoles
      : [requestedUserRoles];

    return roles.some((role) => authUser.hasRole(role));
  });
}

export function requireUserRoleForSystemId(
  queryClient: QueryClient,
  request: Request,
  requestedUserRoles: UserRole | UserRole[],
  systemId: string,
) {
  return checkAuthorizationWithPredicate(queryClient, request, (authUser) => {
    const roles = Array.isArray(requestedUserRoles)
      ? requestedUserRoles
      : [requestedUserRoles];

    return roles.some((role) => authUser.hasRoleForSystemId(role, systemId));
  });
}

export async function loadAuthUser(queryClient: QueryClient) {
  await authService.initialized;
  if (authService.isSignedIn) {
    await queryClient.fetchQuery(authUserQuery);
  }
}
