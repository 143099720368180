import { QueryClient } from '@tanstack/react-query';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { LoaderFunctionArgs, redirect } from 'react-router';
import { SystemsOnlyLoaderData } from '~/pages/SystemDesign/hooks/useSystemsOnly';
import { listSystemsQuery } from '~/shared/api/system.queries';
import {
  checkAuthorization,
  checkIfUserHasPermissionForSystem,
} from '~/shared/auth/auth-utils';
import { createSystemRoute } from '~/shared/models/create-routes';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { assertIsDefined } from '~/types/assert-type';

export const systemDesignLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<SystemsOnlyLoaderData | Response> => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;
    assertIsDefined(systemId);

    const listSystemsResponse =
      await queryClient.ensureQueryData(listSystemsQuery);

    const listSystemsModel = new ListSystemsModel(listSystemsResponse);

    const system = listSystemsModel.systemById(systemId);

    assertIsDefined(system);

    const userHasDesignPermissions = await checkIfUserHasPermissionForSystem(
      queryClient,
      request,
      systemId,
      [
        SystemPermission_Permission.DESIGN,
        SystemPermission_Permission.COMMISSIONING,
      ],
    );

    const systemNotInDesignPhase =
      system.state !== 'ADVICE' && system.state !== 'PRODUCTION_EDIT';

    if (systemNotInDesignPhase || !userHasDesignPermissions) {
      return redirect(createSystemRoute(system.id));
    }

    return {
      systems: listSystemsResponse,
    };
  };
