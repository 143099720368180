import { useParams } from 'react-router';
import { useOptionalSystems } from '~/shared/hooks/systems-hooks';
import { SystemModel } from '~/shared/models/system/SystemModel';
import { assertIsDefined } from '~/types/assert-type';

export const useOptionalSystem = (): SystemModel | undefined => {
  const { systemId } = useParams<{ systemId: string }>();
  const systems = useOptionalSystems();

  return systems?.find((s) => s.id === systemId);
};

export const useRequiredSystem = (): SystemModel => {
  const system = useOptionalSystem();
  assertIsDefined(system);

  return system;
};
